<template>
    <div>
        <template v-if="showPicture">
            <template v-if="assetPictureUrl">
                <div
                    class="detail-image"
                    :style="{ backgroundImage: `url(${assetPictureUrl})` }"
                    @click="showImageInPopUp = true"
                />

                <PopOver
                    v-show="showImageInPopUp"
                    style="z-index: 300"
                    @exit="showImageInPopUp = false"
                >
                    <img :src="assetPictureUrl" class="modal-asset-picture" />
                </PopOver>
            </template>

            <div
                v-if="
                    hasTachographAccess &&
                        tracker.asset_details.asset_type_type === 'truck'
                "
                class="u-bb"
            >
                <TruckInfoMeasurementFragment :tracker="tracker" />
                <TruckLinksMeasurementFragment :tracker="tracker" />
                <TruckRestHistoryMeasurementFragment :tracker="tracker" />
                <TruckTimeSummaryMeasurementFragment :tracker="tracker" />
            </div>
        </template>

        <div class="l-inline l-gap-2 l-padded">
            <AssetAvatar :tracker="tracker" :size="64" />

            <div class="l-stack l-gap-1 l-full-width">
                <div class="l-inline l-center-v l-gap-2">
                    <h3>{{ tracker.asset_details.name }}</h3>

                    <router-link
                        v-if="showActions && hasWritePermission"
                        :to="editLink"
                    >
                        <IconButton :title="$t('edit')">
                            <PencilIcon />
                        </IconButton>
                    </router-link>

                    <IconButton
                        v-if="showActions && hasSharingAccess"
                        :title="$t('share')"
                        @click="handleShare"
                    >
                        <ShareIcon />
                    </IconButton>
                </div>

                <div class="l-inline l-gap-1">
                    <div v-if="networkGsmSignal || (networkRssi && networkSnr)">
                        <SignalStrengthIndicator
                            :network-gsm-signal="networkGsmSignal"
                            :network-rssi="networkRssi"
                            :network-snr="networkSnr"
                        />
                    </div>

                    <div
                        v-if="
                            tracker.last_gps_measurement &&
                                tracker.last_gps_measurement.kind === 'indoor'
                        "
                    >
                        <PinHomeIcon
                            width="24"
                            height="24"
                            :name="$t('positionIndoor')"
                        />
                    </div>
                    <div
                        v-else-if="
                            tracker.last_gps_measurement &&
                                tracker.last_gps_measurement.kind === 'gps-fix'
                        "
                    >
                        <PinEarthIcon
                            width="24"
                            height="24"
                            :name="$t('positionGps')"
                        />
                    </div>
                    <div
                        v-else-if="
                            tracker.last_gps_measurement &&
                                tracker.last_gps_measurement.kind ===
                                    'wifi-report'
                        "
                    >
                        <PinWifiLocationIcon
                            width="24"
                            height="24"
                            :name="$t('wifiPosition')"
                        />
                    </div>

                    <div
                        v-if="batteryStatus"
                        class="l-inline l-center-v l-gap-1"
                    >
                        <BatteryIcon
                            width="24"
                            height="24"
                            :battery-level-in-percent="batteryStatus.value"
                            :warn="batteryStatus.low"
                        />

                        <p>{{ batteryStatus.value }} %</p>
                    </div>
                </div>

                <div
                    v-if="
                        tracker.asset_details &&
                            tracker.asset_details.identification
                    "
                    class="detail-line"
                >
                    <p>
                        {{ $t('identification') }}:
                        {{ tracker.asset_details.identification }}
                    </p>
                </div>

                <div v-if="batteryStatus" class="detail-line">
                    <p>{{ $t('lastBatteryMeasurement') }}:</p>

                    <TimeAgo :from-datetime="batteryStatus.last_update" />
                </div>

                <div
                    v-if="
                        tracker.last_gps_measurement &&
                            tracker.last_gps_measurement.timestamp
                    "
                    class="detail-line"
                >
                    <p>{{ $t('lastGPSMeasurement') }}:</p>

                    <TimeAgo
                        :from-datetime="tracker.last_gps_measurement.timestamp"
                    />

                    <IconButton @click="showDateInfoBox = !showDateInfoBox">
                        <InfoIcon :width="13" :height="13" />
                    </IconButton>
                </div>

                <transition name="dropdown">
                    <InfoBox v-if="showDateInfoBox">
                        {{ $t('datetimeHelp') }}
                    </InfoBox>
                </transition>

                <div
                    v-if="
                        tracker.last_gps_measurement &&
                            tracker.last_gps_measurement.gps_accuracy != null
                    "
                    class="detail-line"
                >
                    <p class="sensor-data-distance">
                        {{ $t('gpsAccuracy') }}:
                        {{
                            Math.round(
                                tracker.last_gps_measurement.gps_accuracy
                            )
                        }}
                    </p>
                </div>

                <div v-if="tracker.last_message_timestamp" class="detail-line">
                    <p>{{ $t('lastContact') }}:</p>

                    <TimeAgo :from-datetime="tracker.last_message_timestamp" />
                </div>

                <div v-if="hasDebugAccess">
                    <p
                        class="t-underline t-subtle"
                        style="cursor: pointer"
                        @click="
                            showAdvancedConnectionInfo = !showAdvancedConnectionInfo
                        "
                    >
                        {{ $t('showadvancedinformation') }}
                    </p>
                </div>

                <transition name="dropdown">
                    <div
                        v-if="hasDebugAccess"
                        v-show="showAdvancedConnectionInfo"
                        class="l-stack l-gap-1"
                    >
                        <p v-if="tracker.model">
                            {{ $t('model') }}: {{ tracker.model }}
                        </p>
                        <p v-if="deveui">DevEUI: {{ deveui }}</p>
                        <p v-if="networkSf">SF: {{ networkSf }}</p>
                        <p v-if="networkRssi">RSSI: {{ networkRssi }}</p>
                        <p v-if="networkSnr">SNR: {{ networkSnr }}</p>
                        <p v-if="networkName">
                            {{ $t('network') }}: {{ networkName }}
                        </p>
                    </div>
                </transition>

                <div
                    v-if="assetTags[tracker.id] && assetTags[tracker.id].length"
                    class="l-inline tags"
                >
                    <div
                        v-for="(tag, index) in assetTags[tracker.id]"
                        :key="index"
                        :class="tag.className"
                    >
                        {{ $t(tag.label) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AssetAvatar from '@/components/AssetAvatar'
import AssetHelpers from '@/mixins/AssetHelpers'
import AssetSharingModal from '@/components/redesigned/AssetSharingModal'
import BatteryIcon from '@/components/icons/BatteryIcon'
import IconButton from '@/components/IconButton'
import InfoBox from '@/components/InfoBox'
import InfoIcon from '@/components/icons/InfoIcon'
import PencilIcon from '@/components/icons/PencilIcon'
import PinEarthIcon from '@/components/icons/PinEarthIcon'
import PinHomeIcon from '@/components/icons/PinHomeIcon'
import PinWifiLocationIcon from '@/components/icons/PinWifiLocationIcon'
import PopOver from '@/components/PopOver'
import ShareIcon from '@/components/icons/ShareIcon'
import SignalStrengthIndicator from './SignalStrengthIndicator'
import TimeAgo from '@/components/TimeAgo'
import TruckInfoMeasurementFragment from '@/components/TruckInfoMeasurementFragment'
import TruckLinksMeasurementFragment from '@/components/TruckLinksMeasurementFragment'
import TruckRestHistoryMeasurementFragment from '@/components/TruckRestHistoryMeasurementFragment'
import TruckTimeSummaryMeasurementFragment from '@/components/TruckTimeSummaryMeasurementFragment'

export default {
    name: 'AssetDetailOverview',
    components: {
        AssetAvatar,
        BatteryIcon,
        IconButton,
        InfoBox,
        InfoIcon,
        PencilIcon,
        PinEarthIcon,
        PinHomeIcon,
        PinWifiLocationIcon,
        PopOver,
        ShareIcon,
        SignalStrengthIndicator,
        TimeAgo,
        TruckInfoMeasurementFragment,
        TruckLinksMeasurementFragment,
        TruckRestHistoryMeasurementFragment,
        TruckTimeSummaryMeasurementFragment,
    },
    mixins: [AssetHelpers],
    props: {
        tracker: {
            type: Object,
            required: true,
        },
        showPicture: {
            type: Boolean,
            default: true,
        },
        showActions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showAdvancedConnectionInfo: false,
            showDateInfoBox: false,
            showImageInPopUp: false,
        }
    },
    computed: {
        ...mapGetters('auth', [
            'hasDebugAccess',
            'hasSharingAccess',
            'hasTachographAccess',
        ]),
        ...mapGetters('tracker', ['assetTags']),
        assetPictureUrl() {
            return (
                this.tracker.asset_details.external_picture ||
                this.tracker.asset_details.picture ||
                this.trackerAssetTypePicture(
                    this.tracker.asset_details.asset_type_identifier
                )
            )
        },
        batteryStatus() {
            return this.tracker.asset_details.sensor_data?.battery
        },
        deveui() {
            return this.tracker.deveui
        },
        editLink() {
            return {
                path: `${this.$route.path}/edit`.replace(/\/+/g, '/'),
                params: {
                    config: this.tracker.config,
                    hasPermission: true,
                },
            }
        },
        hasWritePermission() {
            return this.tracker.asset_details.permissions?.access === 'WRITE'
        },
        networkGsmSignal() {
            return this.tracker.last_measurement?.network_data?.gsm_signal
        },
        networkName() {
            return this.tracker.last_measurement?.network_data?.network
        },
        networkRssi() {
            return this.tracker.last_measurement?.network_data?.rssi
        },
        networkSf() {
            return this.tracker.last_measurement?.network_data?.sf
        },
        networkSnr() {
            return this.tracker.last_measurement?.network_data?.snr
        },
    },
    methods: {
        handleShare() {
            this.$modal.show(
                AssetSharingModal,
                { assetIds: [this.tracker.asset] },
                { width: 400, height: 460 }
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "beaconPosition": "Beacon Position",
        "datetimeHelp": "Trackers do not transmit their position with every message. The latest point of contact with a tracker could have therefore occurred after the last received geographic location.",
        "edit": "Edit",
        "gpsAccuracy": "Accuracy",
        "identification": "Identification",
        "inactive": "INACTIVE",
        "lastBatteryMeasurement": "Last battery measurement",
        "lastContact": "Last contact",
        "lastGPSMeasurement": "Last position",
        "leftZone": "LEFT ZONE",
        "maintenance": "Maintenance due",
        "model": "Model",
        "network": "Network",
        "positionGps": "GPS position",
        "positionIndoor": "Indoor position",
        "share": "Share",
        "showadvancedinformation": "show advanced information",
        "wifiPosition": "WiFi position"
    },
    "de": {
        "beaconPosition": "Beacon Position",
        "datetimeHelp": "Der Tracker schickt nicht in jeder Nachricht seinen Standort. Daraus folgt, dass der letzte Kontakt mit dem Tracker später sein kann als die letzte Standortmessung.",
        "edit": "Bearbeiten",
        "gpsAccuracy": "Genauigkeit",
        "identification": "Identifikation",
        "inactive": "INAKTIV",
        "lastBatteryMeasurement": "Letzte Akku Messung",
        "lastContact": "Letzter Kontakt",
        "lastGPSMeasurement": "Letzte Position",
        "leftZone": "ZONE VERLASSEN",
        "maintenance": "Wartung fällig",
        "model": "Modell",
        "network": "Netzwerk",
        "positionGps": "GPS Position",
        "positionIndoor": "Position im Innenbereich",
        "share": "Teilen",
        "showadvancedinformation": "Erweiterte Informationen anzeigen",
        "wifiPosition": "WiFi Position"
    },
    "fr": {
        "beaconPosition": "Position de la balise",
        "datetimeHelp": "Les trackers ne transmettent pas leur position avec chaque message. Le dernier point de contact avec un tracker peut donc avoir eu lieu après la dernière position géographique reçue.",
        "edit": "Editer",
        "gpsAccuracy": "Précision",
        "identification": "Identification",
        "inactive": "Inactif",
        "lastBatteryMeasurement": "Dernière mesure de la batterie",
        "lastContact": "Dernier contact",
        "lastGPSMeasurement": "Dernière position",
        "leftZone": "QUITTER LA ZONE",
        "maintenance": "Maintenance exigée",
        "model": "Modèle",
        "network": "Réseau",
        "positionGps": "Position GPS",
        "positionIndoor": "Position interne",
        "share": "Partager",
        "showadvancedinformation": "Afficher les informations avancées",
        "wifiPosition": "Position WiFi"
    },
    "it": {
        "beaconPosition": "Beacon Posizione",
        "datetimeHelp": "Il tracker non invia la sua posizione in ogni messaggio. Ciò significa che l'ultimo contatto con l'inseguitore può essere successivo all'ultima misurazione della posizione.",
        "edit": "Condizione",
        "gpsAccuracy": "Precisione",
        "identification": "Identificazione",
        "inactive": "INATTIVO",
        "lastBatteryMeasurement": "Ultimo Misurazione della Batteria",
        "lastContact": "Ultimo Contatto",
        "lastGPSMeasurement": "Ultimo Posizione",
        "leftZone": "ESCONO ZONA",
        "maintenance": "Manutenzione dovuta",
        "model": "Modello",
        "network": "Rete",
        "positionGps": "Posizione GPS",
        "positionIndoor": "Posizione interna",
        "share": "Condividi",
        "showadvancedinformation": "mostra informazioni avanzate",
        "wifiPosition": "Posizione WiFi"
    }
}
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}

.detail-line {
    font-size: $fs-small;

    p {
        display: inline;
        margin-right: 0.3em;
    }

    button {
        margin-left: 0.3em;
        vertical-align: bottom;
    }
}

.detail-image {
    width: 100%;
    height: 200px;
    background-color: $color-gray-lighter;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    cursor: pointer;
}

.tags {
    flex-wrap: wrap;

    & > div {
        margin: 0 4px 4px 0;
        white-space: nowrap;
    }
}

.dropdown {
    &-enter,
    &-leave-to {
        max-height: 0;
        opacity: 0;
    }

    &-leave,
    &-enter-to {
        max-height: 400px;
        opacity: 1;
    }

    &-enter-active,
    &-leave-active {
        transition: max-height 0.2s linear, opacity 0.4s ease-in-out;
    }
}

.modal-asset-picture {
    max-width: 95vw;
    max-height: 95vh;
}
</style>
